import { ref } from '@vue/composition-api'
import {
  parseRequestError,
} from '@/helpers/helpers'
import contactRequests from '@/http/requests/customers/contacts'
import i18n from '@/libs/i18n'

export default () => {
  const organizations = ref([])
  const serviceKeys = ref([
    {
      key: 'name',
      label: i18n.t('customer.contacts.services.service'),
    }, {
      key: 'duration',
      label: i18n.t('customer.contacts.services.duration'),
    }, {
      key: 'priceGross',
      label: i18n.t('customer.contacts.services.price'),
    },
  ])

  contactRequests.getCustomerContacts()
    .then(res => {
      organizations.value = res.data
    })
    .catch(err => parseRequestError(err))

  const toHours = val => Math.floor(val / 60).toString()

  const toMinutes = val => (val - Math.floor(val / 60) * 60).toString().padStart(2, '0')

  return {
    organizations,
    serviceKeys,

    toHours,
    toMinutes,
  }
}
