<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >
        <b-row class="match-height">
          <b-col
            v-for="(organization, index) in organizations"
            :key="index"
            cols="12"
            md="6"
          >
            <b-card
              :img-src="require('@/assets/images/customers/contacts/cp-pixel.png')"
              :img-alt="organization.address.companyName"
              img-top
              class="card-profile"
            >
              <div class="profile-image-wrapper">
                <div class="profile-image p-0">
                  <b-avatar
                    v-if="false"
                    size="114"
                    variant="light-primary"
                    :src="null"
                  />
                  <b-avatar
                    v-else
                    size="114"
                    variant="light-primary"
                  >
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="60"
                    />
                  </b-avatar>
                </div>
              </div>

              <div class="text-center">
                <h3>{{ organization.shortName }}</h3>
                <h6 class="font-weight-bold">
                  <span v-if="organization.address.companyName !== organization.shortName">
                    {{ organization.address.companyName }}
                  </span>
                </h6>
                <h6 class="text-muted">
                  <span v-if="organization.address.addressLine1">
                    {{ organization.address.addressLine1 }},
                  </span>
                  {{ organization.address.zipCode }}
                  {{ organization.address.city }}
                </h6>
                <b-badge
                  v-if="organization.address.taxIdentifier"
                  class="profile-badge mt-0"
                  variant="light-primary"
                >
                  NIP: {{ organization.address.taxIdentifier }}
                </b-badge>
              </div>

              <div v-if="organization.publicServices.length">
                <b-button
                  v-b-toggle="`services-organization-${index}`"
                  variant="flat-primary btn-block"
                >
                  {{ $t('customer.contacts.services.show') }}
                </b-button>

                <b-collapse
                  :id="`services-organization-${index}`"
                  class="mt-2"
                >
                  <b-table
                    :items="organization.publicServices"
                    :fields="serviceKeys"
                    responsive
                  >
                    <!-- Column: duration -->
                    <template #cell(duration)="data">
                      <div class="text-right">
                        {{ toHours(data.item.duration) }}:{{ toMinutes(data.item.duration) }}
                      </div>
                    </template>
                    <!-- Column: priceGross -->
                    <template #cell(priceGross)="data">
                      <div class="text-right text-nowrap">
                        {{ data.item.priceGross }} zł
                      </div>
                    </template>
                  </b-table>
                </b-collapse>
              </div>

              <hr class="my-1">

              <!-- contacts -->
              <b-row>
                <b-col class="text-center mt-1 ">
                  <a
                    v-if="organization.phone"
                    :href="`tel:${organization.phone.replace(/ /g, '')}`"
                    class="text-center"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="32"
                    />
                    <div class="mb-0 mt-1 font-weight-bold text-nowrap">
                      {{ organization.phone }}
                    </div>
                  </a>
                </b-col>

                <b-col class="text-center mt-1 ">
                  <a
                    v-if="organization.email"
                    :href="`mailto:${organization.email}`"
                    target="_blank"
                    class="text-center"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="32"
                    />
                    <div class="mb-0 mt-1 font-weight-bold">
                      {{ organization.email }}
                    </div>
                  </a>
                </b-col>
              </b-row>
              <!--/ follower projects rank -->
            </b-card>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VBToggle, VBTooltip } from 'bootstrap-vue'
import contactsSetup from '@/views/customer/contacts/contactsSetup'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'v-b-toggle': VBToggle,
  },
  setup: contactsSetup,
}
</script>

<style lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.b-overlay {
  position: relative !important;
  min-height: 100px;
}
</style>
